@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300&display=swap");

//------------------- ANIMATIONS ----------------------
@keyframes focus-on {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes focus-off {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes menuEnter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//------------------- RESETS ----------------------
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  text-decoration: none;
  list-style: none;
  font-family: "Tajawal", sans-serif;
  color: black;
}

body {
  font-size: 10px;
  overflow-x: hidden;
}

//------------------- MOBILE ----------------------
//util classes

//errors
.error.image {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.error.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100vw;
  height: 100vh;
  font-size: 0.8rem;
  img {
    margin: 20px auto;
    width: 30px;
  }
}

.error.weather {
  font-size: 0.8rem;
}

.error.favourites {
  height: 90px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 0.8rem;
}

//conditional components. Fetching placeholder and back to top button
.fetching {
  display: flex;
  background-color: hsla(0, 0%, 75%, 0.9);
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: fixed;
  bottom: 25px;
  left: 0;
  right: 0;
  img {
    height: 20px;
    animation: spin 2s ease-in-out infinite;
  }
}

.toTop {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  width: 40px;
  height: 40px;
  bottom: 10%;
  right: 5%;
  border: solid 2px hsla(0, 0%, 50%, 1);
  border-radius: 20px;
  animation: fade-in 750ms ease-in-out backwards;
  img {
    transform: rotate(180deg);
  }
}
.toTop:hover {
  border-color: hsla(0, 0%, 50%, 1);
}

//header and nav
.fixedBanner {
  width: 100vw;
  z-index: 999;
  position: fixed;
  top: 0;
  background-color: hsla(0, 0%, 100%, 0.8);
}

.header {
  padding: 20px 0 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .logo {
    font-size: 1.5rem;
  }
  .burger {
    position: absolute;
    top: 20px;
    right: 30px;
    height: 30px;
    width: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav {
    flex-direction: column;
    justify-content: space-evenly;
    height: 90vh;
    width: 100%;
    li {
      font-size: 1.2rem;
    }
    li:hover > a {
      color: hsl(22, 100%, 50%);
    }
  }
  .open {
    display: flex;
  }
  .close {
    display: none;
  }
}

//weather banner
.weather--banner {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  justify-content: space-between;
  .weather--day {
    max-height: 30px;
    font-size: 0.9rem;
    min-width: 250px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    img,
    p {
      padding: 3px;
    }
    img {
      height: 30px;
    }
  }
}

//feed and articles
.feed--container {
  max-width: 95vw;
  margin: 90px auto 0;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  .feed--article {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: 1px solid white;
    border-radius: 0.5rem;
    padding: 5px;
    font-size: 0.9rem;
    .feed--article--head {
      display: flex;
      text-align: justify;
      .feed--article--info {
        padding: 5px;
      }
      .feed--article--follow {
        min-width: 100px;
        align-self: flex-start;
        padding: 20px 5px;
        text-align: center;
      }
      .feed--article--follow:hover {
        color: hsl(22, 100%, 50%);
      }
      .feed--article--follow:active {
        transform: scale(0.9);
      }
    }
    img {
      width: 100%;
      object-fit: cover;
      cursor: pointer;
      opacity: 0.5;
      border-radius: 0.5rem;
      animation: focus-off 500ms ease-in-out backwards alternate;
    }
  }
  .feed--article:hover > img {
    animation: focus-on 500ms ease-in-out forwards alternate;
  }
}

//scrollbar styling
.feed--container {
  scrollbar-width: thin;
}

.feed--container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.feed--container::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.feed--container::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border: 0px none #ffffff;
  border-radius: 26px;
}
.feed--container::-webkit-scrollbar-thumb:hover {
  background: #aeaeae;
}
.feed--container::-webkit-scrollbar-thumb:active {
  background: #aeaeae;
}
.feed--container::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.feed--container::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
.feed--container::-webkit-scrollbar-track:active {
  background: #ffffff;
}
.feed--container::-webkit-scrollbar-corner {
  background: transparent;
}

.myAccount--container {
  max-width: 80vw;
  margin: 130px auto 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  .myAccount--section--container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid hsla(0, 0%, 60%, 1);
    border-radius: 10px;
    margin-bottom: 20px;
    > h3 {
      width: 100%;
      font-size: 1rem;
      font-weight: 300;
      text-align: left;
      text-decoration: underline;
    }
    .myAccount--details {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      justify-content: center;
      justify-items: center;
      .details--grid--span {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      p,
      button {
        font-size: 1rem;
      }
      button {
        color: hsl(22, 100%, 50%);
        width: 50px;
        background-color: inherit;
      }
      button:active {
        transform: scale(0.9);
      }
      .details--edit {
        background-color: hsla(0, 0%, 95%, 1);
        font-size: 1rem;
        padding: 5px;
        border-radius: 5px;
      }
    }
    .myAccount--favourites {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      max-width: 90%;
      margin: auto;
      justify-content: center;
      p {
        margin: 10px;
        font-size: 0.9rem;
      }

      p:hover {
        color: hsl(22, 100%, 50%);
        cursor: pointer;
      }
      p:active {
        transform: scale(0.9);
      }
    }
  }
}

.login--container {
  max-width: 80vw;
  height: 100vh;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .login--form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    justify-items: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 26px -2px rgba(30, 30, 60, 0.25);
    label {
      font-size: 1rem;
    }
    input {
      background-color: hsla(0, 0%, 95%, 1);
      font-size: 1rem;
      padding: 5px;
      border-radius: 5px;
    }
    h3 {
      font-size: 1.2rem;
    }
    .login--grid--span {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    button {
      border-radius: 5px;
      padding: 5px;
      width: 50%;
    }
    button:hover {
      color: hsl(22, 100%, 50%);
    }
    button:active {
      box-shadow: 0px 0px 13px 2px rgba(30, 30, 60, 0.25);
    }
  }
  .login--signup {
    margin-top: 10px;
    font-size: 0.9rem;
    a {
      text-decoration: underline;
    }
    a:hover {
      color: hsl(22, 100%, 50%);
    }
  }
  .login--error {
    color: hsl(0, 100%, 50%);
    max-width: 50%;
  }
  .login--options {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: space-around;
    height: 200px;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0px 10px 26px -2px rgba(30, 30, 60, 0.25);
    a {
      font-size: 0.9rem;
      padding: 10px;
    }
  }
}

//------------------- IPAD ----------------------
@media screen and (min-width: 768px) {
  .burger {
    display: none;
  }

  .fixedBanner {
    background-color: hsla(0, 0%, 100%, 1);
  }

  .header {
    .nav {
      height: unset;
      flex-direction: row;
    }
    .close {
      display: flex;
    }
  }

  .feed--container {
    height: 75vh;
    margin: 120px auto 0;
    flex-direction: row;
    flex-wrap: wrap;
    .feed--article {
      justify-content: space-between;
      width: 50%;
      .feed--article--head {
        height: unset;
      }
    }
  }
}

.login--container {
  height: 80vh;
  margin-top: 100px;
}

//DESKTOP
@media (min-width: 1440px) {
  .feed--container {
    height: 80vh;
    max-width: 80%;
    .feed--article {
      margin: 0 auto 10px;
      align-content: center;
      width: 45%;
    }
  }
}
