@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300&display=swap");
@-webkit-keyframes focus-on {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes focus-on {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes focus-off {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes focus-off {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes menuEnter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes menuEnter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 0;
  text-decoration: none;
  list-style: none;
  font-family: "Tajawal", sans-serif;
  color: black;
}

body {
  font-size: 10px;
  overflow-x: hidden;
}

.error.image {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.error.news {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  width: 100vw;
  height: 100vh;
  font-size: 0.8rem;
}

.error.news img {
  margin: 20px auto;
  width: 30px;
}

.error.weather {
  font-size: 0.8rem;
}

.error.favourites {
  height: 90px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.8rem;
}

.fetching {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(191, 191, 191, 0.9);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: fixed;
  bottom: 25px;
  left: 0;
  right: 0;
}

.fetching img {
  height: 20px;
  -webkit-animation: spin 2s ease-in-out infinite;
          animation: spin 2s ease-in-out infinite;
}

.toTop {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  font-size: 20px;
  width: 40px;
  height: 40px;
  bottom: 10%;
  right: 5%;
  border: solid 2px gray;
  border-radius: 20px;
  -webkit-animation: fade-in 750ms ease-in-out backwards;
          animation: fade-in 750ms ease-in-out backwards;
}

.toTop img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.toTop:hover {
  border-color: gray;
}

.fixedBanner {
  width: 100vw;
  z-index: 999;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.header {
  padding: 20px 0 5px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.header .logo {
  font-size: 1.5rem;
}

.header .burger {
  position: absolute;
  top: 20px;
  right: 30px;
  height: 30px;
  width: 30px;
}

.header .burger img {
  width: 100%;
  height: 100%;
}

.header .nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  height: 90vh;
  width: 100%;
}

.header .nav li {
  font-size: 1.2rem;
}

.header .nav li:hover > a {
  color: #ff5e00;
}

.header .open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header .close {
  display: none;
}

.weather--banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  max-width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.weather--banner .weather--day {
  max-height: 30px;
  font-size: 0.9rem;
  min-width: 250px;
  margin: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.weather--banner .weather--day img,
.weather--banner .weather--day p {
  padding: 3px;
}

.weather--banner .weather--day img {
  height: 30px;
}

.feed--container {
  max-width: 95vw;
  margin: 90px auto 0;
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.feed--container .feed--article {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 5px;
  font-size: 0.9rem;
}

.feed--container .feed--article .feed--article--head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: justify;
}

.feed--container .feed--article .feed--article--head .feed--article--info {
  padding: 5px;
}

.feed--container .feed--article .feed--article--head .feed--article--follow {
  min-width: 100px;
  -ms-flex-item-align: start;
      align-self: flex-start;
  padding: 20px 5px;
  text-align: center;
}

.feed--container .feed--article .feed--article--head .feed--article--follow:hover {
  color: #ff5e00;
}

.feed--container .feed--article .feed--article--head .feed--article--follow:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.feed--container .feed--article img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
  opacity: 0.5;
  border-radius: 0.5rem;
  -webkit-animation: focus-off 500ms ease-in-out backwards alternate;
          animation: focus-off 500ms ease-in-out backwards alternate;
}

.feed--container .feed--article:hover > img {
  -webkit-animation: focus-on 500ms ease-in-out forwards alternate;
          animation: focus-on 500ms ease-in-out forwards alternate;
}

.feed--container {
  scrollbar-width: thin;
}

.feed--container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.feed--container::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.feed--container::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border: 0px none #ffffff;
  border-radius: 26px;
}

.feed--container::-webkit-scrollbar-thumb:hover {
  background: #aeaeae;
}

.feed--container::-webkit-scrollbar-thumb:active {
  background: #aeaeae;
}

.feed--container::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.feed--container::-webkit-scrollbar-track:hover {
  background: #ffffff;
}

.feed--container::-webkit-scrollbar-track:active {
  background: #ffffff;
}

.feed--container::-webkit-scrollbar-corner {
  background: transparent;
}

.myAccount--container {
  max-width: 80vw;
  margin: 130px auto 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.myAccount--container .myAccount--section--container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 15px;
  border: 1px solid #999999;
  border-radius: 10px;
  margin-bottom: 20px;
}

.myAccount--container .myAccount--section--container > h3 {
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  text-decoration: underline;
}

.myAccount--container .myAccount--section--container .myAccount--details {
  margin-top: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  justify-items: center;
}

.myAccount--container .myAccount--section--container .myAccount--details .details--grid--span {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 3;
}

.myAccount--container .myAccount--section--container .myAccount--details p,
.myAccount--container .myAccount--section--container .myAccount--details button {
  font-size: 1rem;
}

.myAccount--container .myAccount--section--container .myAccount--details button {
  color: #ff5e00;
  width: 50px;
  background-color: inherit;
}

.myAccount--container .myAccount--section--container .myAccount--details button:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.myAccount--container .myAccount--section--container .myAccount--details .details--edit {
  background-color: #f2f2f2;
  font-size: 1rem;
  padding: 5px;
  border-radius: 5px;
}

.myAccount--container .myAccount--section--container .myAccount--favourites {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 90%;
  margin: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.myAccount--container .myAccount--section--container .myAccount--favourites p {
  margin: 10px;
  font-size: 0.9rem;
}

.myAccount--container .myAccount--section--container .myAccount--favourites p:hover {
  color: #ff5e00;
  cursor: pointer;
}

.myAccount--container .myAccount--section--container .myAccount--favourites p:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.login--container {
  max-width: 80vw;
  height: 100vh;
  margin: auto;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.login--container .login--form {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 15px;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 26px -2px rgba(30, 30, 60, 0.25);
          box-shadow: 0px 10px 26px -2px rgba(30, 30, 60, 0.25);
}

.login--container .login--form label {
  font-size: 1rem;
}

.login--container .login--form input {
  background-color: #f2f2f2;
  font-size: 1rem;
  padding: 5px;
  border-radius: 5px;
}

.login--container .login--form h3 {
  font-size: 1.2rem;
}

.login--container .login--form .login--grid--span {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 3;
}

.login--container .login--form button {
  border-radius: 5px;
  padding: 5px;
  width: 50%;
}

.login--container .login--form button:hover {
  color: #ff5e00;
}

.login--container .login--form button:active {
  -webkit-box-shadow: 0px 0px 13px 2px rgba(30, 30, 60, 0.25);
          box-shadow: 0px 0px 13px 2px rgba(30, 30, 60, 0.25);
}

.login--container .login--signup {
  margin-top: 10px;
  font-size: 0.9rem;
}

.login--container .login--signup a {
  text-decoration: underline;
}

.login--container .login--signup a:hover {
  color: #ff5e00;
}

.login--container .login--error {
  color: red;
  max-width: 50%;
}

.login--container .login--options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-line-pack: distribute;
      align-content: space-around;
  height: 200px;
  width: 200px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 26px -2px rgba(30, 30, 60, 0.25);
          box-shadow: 0px 10px 26px -2px rgba(30, 30, 60, 0.25);
}

.login--container .login--options a {
  font-size: 0.9rem;
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .burger {
    display: none;
  }
  .fixedBanner {
    background-color: white;
  }
  .header .nav {
    height: unset;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .header .close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .feed--container {
    height: 75vh;
    margin: 120px auto 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .feed--container .feed--article {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 50%;
  }
  .feed--container .feed--article .feed--article--head {
    height: unset;
  }
}

.login--container {
  height: 80vh;
  margin-top: 100px;
}

@media (min-width: 1440px) {
  .feed--container {
    height: 80vh;
    max-width: 80%;
  }
  .feed--container .feed--article {
    margin: 0 auto 10px;
    -ms-flex-line-pack: center;
        align-content: center;
    width: 45%;
  }
}
